import { ActivityFeedHydration, ActivityFeedStore } from './ActivityFeedStore';
import { UserHydration, UserStore } from './UserStore';
import { UIHydration, UIStore } from './UIStore';
import { DrawHydration, DrawStore } from './DrawStore';
import { EnterStore } from './EnterStore';
import { CookieConsentStore } from './CookieConsentStore';
import { RoktHydration, RoktStore } from './RoktStore';
import { reaction, set } from 'mobx';
import { ScrollStore } from './ScrollStore';
import { PrizeHydration, PrizeStore } from './PrizeStore';
export type RootStoreHydration = {
  userStore?: UserHydration;
  activityFeedStore?: ActivityFeedHydration;
  UIStore?: UIHydration;
  drawStore?: DrawHydration;
  cookieConsentStore?: CookieConsentStore;
  roktStore?: RoktHydration;
  prizeStore?: PrizeHydration;
};

export class RootStore {
  userStore: UserStore;
  activityFeedStore: ActivityFeedStore;
  UIStore: UIStore;
  drawStore: DrawStore;
  enterStore: EnterStore;
  cookieConsentStore: CookieConsentStore;
  roktStore: RoktStore;
  scrollStore: ScrollStore;
  prizeStore: PrizeStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.activityFeedStore = new ActivityFeedStore(this);
    this.UIStore = new UIStore(this);
    this.drawStore = new DrawStore(this);
    this.enterStore = new EnterStore(this);
    this.cookieConsentStore = new CookieConsentStore(this);
    this.roktStore = new RoktStore(this);
    this.scrollStore = new ScrollStore(this);
    this.prizeStore = new PrizeStore(this);

    if (typeof window !== 'undefined') {
      this.loadStoreLocalStorage(this.enterStore, 'enterStoreData');

      reaction(
        () => JSON.stringify(this.enterStore.getData()),
        (json) => this.saveStoreLocalStorage('enterStoreData', json)
      );
    }
  }

  hydrate(data: RootStoreHydration) {
    if (data.userStore) {
      this.userStore.hydrate(data.userStore);
    }
    if (data.activityFeedStore) {
      this.activityFeedStore.hydrate(data.activityFeedStore);
    }
    if (data.UIStore) {
      this.UIStore.hydrate(data.UIStore);
    }
    if (data.drawStore) {
      this.drawStore.hydrate(data.drawStore);
    }
    if (data.cookieConsentStore) {
      this.cookieConsentStore.hydrate(data.cookieConsentStore);
    }
    if (data.roktStore) {
      this.roktStore.hydrate(data.roktStore);
    }
    if (data.prizeStore) {
      this.prizeStore.hydrate(data.prizeStore);
    }
  }

  loadStoreLocalStorage = (store: object, name: string) => {
    const jsonString = localStorage.getItem(name);
    const loadedSettings = jsonString ? JSON.parse(jsonString) : undefined;

    if (loadedSettings) {
      set(store, loadedSettings);
    }
  };

  saveStoreLocalStorage = (name: string, json: string) => {
    localStorage.setItem(name, json);
  };
}
