import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { TDrawDays, TSubscriptionType } from 'types/Subscription';
import {
  validatePromoCode,
  validatePromoCodeUnauthenticated,
} from 'utils/api/discount';
import {
  PromoCodeRewards,
  bundleRequirementsCodeFromEntryMethod,
} from 'components/enter/PaymentSection';
import { EntryMethod } from 'components/enter/EntryMethod';
import { getCookie } from 'utils/cookie';
import { PaymentSchedule } from 'utils/constants';
import { IPromoCodeApplicationResult } from 'types/PromoCode';
import { lineIsValid } from 'utils/TicketValidation';
import {
  formattedPriceStringFromMicroCurrency,
  generateRandomLineNumbers,
} from 'utils/common';
import { getRequirementsCodeDescription } from 'utils/promo-code-utils';
import { generateRandomNumbers } from 'utils/TicketUtil';

interface IEntryLine {
  main: (number | null)[];
  bonus: number | null;
}

const emptyLine = { main: [null, null, null, null], bonus: null };

export class EnterStore {
  root: RootStore;

  initialised: boolean = false;
  initialised_promocode: boolean = false;
  checked_user_referral: boolean = false;
  confirmed: boolean = false;
  entryMethod: keyof typeof EntryMethod = 'PremiumSubscription';
  num_entries: number = 1;
  lines: IEntryLine[] = [];
  days: TDrawDays = 'TUESDAY';
  paymentSchedule = PaymentSchedule.WEEKLY;
  boosted: boolean = false;
  promo_code: string | null = null;

  upgradePageSource = false;

  promoCodeApplicationResult: IPromoCodeApplicationResult | null = null;
  discountMultiplier: number = 0;
  discountMicroAmount: number = 0;
  discountBannerText: string | null = null;

  regularOrderCost: number = 0;
  payNowCost: number = 0;

  orderId: string | null = null;
  adyenPaymentSession: string | null = null;
  orderError: string | null = null;
  adyenPaymentSessionTime: number | null = null;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      initialised: observable,
      initialised_promocode: observable,
      checked_user_referral: observable,
      confirmed: observable,
      entryMethod: observable,
      num_entries: observable,
      lines: observable,
      days: observable,
      paymentSchedule: observable,
      boosted: observable,
      promo_code: observable,
      promoCodeApplicationResult: observable,

      discountMultiplier: observable,
      discountMicroAmount: observable,
      discountBannerText: observable,

      regularOrderCost: observable,
      payNowCost: observable,
      orderId: observable,
      adyenPaymentSession: observable,
      adyenPaymentSessionTime: observable,
      orderError: observable,

      setInitialised: action,
      setInitialised_promocode: action,
      setCheckedUserReferral: action,

      setEntryMethod: action,
      setNumEntries: action,
      setLines: action,
      setLine: action,
      toggleLineNumber: action,
      toggleLineBonusNumber: action,

      setDays: action,
      toggleDay: action,
      setBoosted: action,
      setPromoCode: action,
      setPromoCodeApplicationResult: action,
      processPromoCode: action,
      setOrder: action,
      setOrderError: action,
      setOrderCost: action,
      resetOrder: action,
      setConfirmed: action,
      validateOrder: action,
    });
  }

  getData = () => {
    const persistAttributes = [
      'confirmed',
      'entryMethod',
      'num_entries',
      'lines',
      'days',
      'paymentSchedule',
      'boosted',
      'promo_code',
      'upgradePageSource',
      'discountMultiplier',
      'discountMicroAmount',
      'discountBannerText',
      'regularOrderCost',
      'payNowCost',
      'orderId',
      'adyenPaymentSession',
      'adyenPaymentSessionTime',
      'orderError',
    ];
    return Object.fromEntries(
      persistAttributes.map((x) => [x, this[x as keyof EnterStore]])
    );
  };

  setInitialised = (initialised: boolean) => {
    this.initialised = initialised;
  };

  setInitialised_promocode = (initialised_promocode: boolean) => {
    this.initialised_promocode = initialised_promocode;
  };

  setCheckedUserReferral = (checked_user_referral: boolean) => {
    this.checked_user_referral = checked_user_referral;
  };

  resetOrder = () => {
    this.orderId = null;
    this.adyenPaymentSession = null;
    this.adyenPaymentSessionTime = null;
    this.orderError = null;
    this.confirmed = false;
  };

  resetEnter = () => {
    this.setNumEntries(0);
    this.setNumEntries(1);
    this.setBoosted(false);
    this.setDays('TUESDAY');
  };

  setEntryMethod = (entryMethod: keyof typeof EntryMethod) => {
    console.log('set entryMethod', entryMethod);

    if (this.entryMethod != entryMethod) {
      this.entryMethod = entryMethod;

      this.resetEnter();

      this.processPromoCode();
      this.resetOrder();
    }
  };

  setNumEntries = (num_entries: number) => {
    console.log('set num_entries', num_entries);
    this.num_entries = num_entries;

    if (this.lines.length > num_entries) {
      this.setLines(this.lines.slice(0, num_entries));
    } else if (this.lines.length < num_entries) {
      const newEntries = [];
      while (this.lines.length < num_entries) {
        this.lines.push(this.generateRandomNumbers());
      }
      this.setLines(this.lines);
    }
    this.resetOrder();
  };

  setLines = (lines: IEntryLine[]) => {
    console.log('set lines', lines);
    this.lines = lines;
    this.resetOrder();
  };

  setLine = (line_number: number, line: IEntryLine) => {
    console.log('setLine line_number', line_number, line);
    this.lines[line_number] = line;
    this.resetOrder();
  };

  setLineNumber = (line_number: number, position: number, value: number) => {
    if (line_number >= this.num_entries) {
      throw Error('line_number bigger than num_entries');
    }
    this.lines[line_number]['main'][position] = value;
    this.resetOrder();
  };

  removeLine = (line_number: number) => {
    this.lines.splice(line_number, 1);
    this.setNumEntries(this.num_entries - 1);
  };

  toggleLineNumber = (line_number: number, value: number) => {
    if (line_number >= this.num_entries) {
      console.log(
        'line_number',
        line_number,
        'this.num_entries',
        this.num_entries
      );
      throw Error('line_number bigger than num_entries');
    }

    const haveNum = this.lines[line_number]['main'].indexOf(value);

    if (haveNum != -1) {
      this.lines[line_number]['main'][haveNum] = null;
    } else {
      const emptySpace = this.lines[line_number]['main'].indexOf(null);

      if (emptySpace != -1) {
        this.lines[line_number]['main'][emptySpace] = value;
      }
    }
    this.resetOrder();
  };

  toggleLineBonusNumber = (line_number: number, value: number) => {
    if (line_number >= this.num_entries) {
      throw Error('line_number bigger than num_entries');
    }

    if (value === this.lines[line_number]['bonus']) {
      this.lines[line_number]['bonus'] = null;
    } else {
      this.lines[line_number]['bonus'] = value;
    }

    this.resetOrder();
  };

  generateLineRandomNumbers = (line_number: number) => {
    this.setLine(line_number, this.generateRandomNumbers());
  };

  generateRandomNumbers = () => {
    const ballRanges = this.root.drawStore.ballRanges;
    const usingBonusBall = this.root.drawStore.usingBonusBall;
    if (ballRanges && usingBonusBall) {
      return generateRandomNumbers(ballRanges, usingBonusBall);
    }

    return emptyLine;
  };

  setDays = (days: TDrawDays) => {
    console.log('set days', days);
    this.days = days;

    this.resetOrder();
  };

  toggleDay = (day: 'TUESDAY' | 'FRIDAY') => {
    const binDays = {
      TUESDAY: this.days == 'BOTH' || this.days == 'TUESDAY',
      FRIDAY: this.days == 'BOTH' || this.days == 'FRIDAY',
    };

    binDays[day] = !binDays[day];

    this.days =
      binDays.TUESDAY && binDays.FRIDAY
        ? 'BOTH'
        : binDays.TUESDAY
        ? 'TUESDAY'
        : day === 'TUESDAY'
        ? 'FRIDAY'
        : 'TUESDAY';
    this.resetOrder();
  };

  setBoosted = (boosted: boolean) => {
    console.log('set boosted', boosted);
    this.boosted = boosted;
    this.resetOrder();
  };

  setPromoCode = async (promo_code: string | null) => {
    console.log('set promo_code', promo_code);
    this.promo_code = promo_code;

    await this.processPromoCode();
  };

  setPromoCodeApplicationResult = (result: IPromoCodeApplicationResult) => {
    console.log('setPromoCodeApplicationResult', result);

    this.promoCodeApplicationResult = result;

    this.resetOrder();
  };

  processPromoCode = async () => {
    if (this.promo_code) {
      const jwt = getCookie('jwt');

      const result = jwt
        ? await validatePromoCode(
            jwt,
            this.promo_code.toUpperCase(),
            this.lines.length,
            bundleRequirementsCodeFromEntryMethod(this.entryMethod)
          )
        : await validatePromoCodeUnauthenticated(
            this.promo_code.toUpperCase(),
            this.lines.length,
            bundleRequirementsCodeFromEntryMethod(this.entryMethod)
          );

      const paidSubscription = [
        EntryMethod.PremiumSubscription,
        EntryMethod.LiteSubscription,
      ].includes(this.entryMethod);

      this.discountMultiplier = 0;
      this.discountMicroAmount = 0;
      this.discountBannerText = null;

      if (
        (['SUBSCRIPTION', 'WEEKLY_DRAW_SUBSCRIPTION'].includes(
          result?.rewardRequirementsCode
        ) &&
          !paidSubscription) ||
        result?.rewardRequirementsCode === 'WOWCHER'
      ) {
        this.setPromoCodeApplicationResult({
          successful: false,
          reason: getRequirementsCodeDescription(result.rewardRequirementsCode),
        });
      } else {
        this.setPromoCodeApplicationResult(result);

        if (result?.successful) {
          const { rewardCode } = result;
          switch (rewardCode) {
            case PromoCodeRewards.DISCOUNT_PERCENTAGE:
              const discountPercentage = parseInt(result.rewardValue, 10);
              this.discountMultiplier = discountPercentage / 100;
            case PromoCodeRewards.DISCOUNT_ENTRY:
              this.discountMicroAmount = result.discountAsMicroAmount;
              this.discountBannerText = 'Promo Applied';
          }
        }
      }
    }

    this.resetOrder();
  };

  getPromoCodeMessage = () => {
    if (
      !this.promoCodeApplicationResult ||
      !this.promoCodeApplicationResult.successful
    ) {
      return null;
    }
    const { rewardValue, rewardCode, totalRecurrences, discountAsMicroAmount } =
      this.promoCodeApplicationResult;

    switch (rewardCode) {
      case PromoCodeRewards.FREE_ENTRY:
        // @ts-ignore : stupid change of type of rewardValue to list of entries
        const numFreeEntries = rewardValue.length;

        return `Promo code successfully applied. ${numFreeEntries} ${
          numFreeEntries === 1 ? 'free entry ' : 'free entries '
        } will be added.`;

      case PromoCodeRewards.FREE_ENTRY_AND_EXTRA_TREE:
        // @ts-ignore : stupid change of type of rewardValue to list of entries
        const numFreeEntries2 = rewardValue.length;
        return `Promo code successfully applied. ${numFreeEntries2} ${
          numFreeEntries2 === 1 ? 'free entry ' : 'free entries '
        } will be added and you will also plant an additional tree with your purchase!`;

      case PromoCodeRewards.DISCOUNT_PERCENTAGE:
        return `Your promo code has successfully been applied. Your order has been discounted by ${rewardValue}%${
          totalRecurrences && totalRecurrences > 0
            ? ` and will continue to be discounted for ${totalRecurrences} more week${
                totalRecurrences > 1 ? 's' : ''
              }`
            : ''
        }.`;

      case PromoCodeRewards.DISCOUNT_ENTRY:
        return `Your order has been discounted by the cost of ${
          rewardValue === 1 ? 'an entry' : `${rewardValue} entries`
        } (${formattedPriceStringFromMicroCurrency(discountAsMicroAmount)})${
          totalRecurrences && totalRecurrences > 0
            ? ` and will continue to be discounted for ${totalRecurrences} more week${
                totalRecurrences > 1 ? 's' : ''
              }`
            : ''
        }.`;

      case PromoCodeRewards.FREE_1ST_SUBSCRIPTION_ENTRY:
        return `Your promo code has successfully been applied. Your order has been discounted by the cost of one entry.`;

      default:
        console.log(`Don't know what to do with rewardCode: ${rewardCode}`);
    }
  };

  setOrder = (orderId: string, adyenPaymentSession: string) => {
    this.orderId = orderId;
    this.adyenPaymentSession = adyenPaymentSession;
    this.adyenPaymentSessionTime = Date.now();
  };

  setOrderError = (error: string) => {
    this.orderError = error;
  };

  setOrderCost = (regularCost: number, payNowCost: number) => {
    console.log('setOrderCost', { regularCost, payNowCost });
    this.regularOrderCost = regularCost;
    this.payNowCost = payNowCost;
  };

  setConfirmed = () => {
    this.confirmed = true;
  };

  validateOrder = () => {
    const ballRanges = this.root.drawStore.ballRanges;
    const usingBonusBall = this.root.drawStore.usingBonusBall;

    const hasErrors = this.lines.some(
      (line) =>
        !lineIsValid([...line.main, line.bonus], ballRanges!, usingBonusBall)
    );
    this.confirmed = !hasErrors;

    return !hasErrors;
  };
}
