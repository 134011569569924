import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { fetchTrendingPrizes } from 'utils/api/prizes';
import { Prize } from 'types/Prize';

export type PrizeHydration = {
  trendingPrizes?: Prize[];
};

export class PrizeStore {
  root: RootStore;
  trendingPrizes?: Prize[] = [];

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      trendingPrizes: observable,
    });

    this.initAsync();
  }

  async initAsync() {
    await this.fetchTrendingPrizes();
  }

  protected async fetchTrendingPrizes() {
    this.trendingPrizes = await fetchTrendingPrizes();
  }

  hydrate(data?: PrizeHydration) {
    if (data && data.trendingPrizes) {
      this.trendingPrizes = data.trendingPrizes;
    }
  }
}
