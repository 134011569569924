import type { AppProps } from "next/app";
import { RootStoreProvider } from "providers/RootStoreProvider";
import "../asset/css/global.css";
import "../public/montserrat/montserrat.css";
import LoadingOverlay from "../components/layout/LoadingOverlay";
import { DefaultSeo } from "next-seo";
import SEO from "../next-seo.config";
import * as Sentry from "@sentry/browser";
import Maintenance from "components/Maintenance";
import {
  GOOGLE_CLIENT_ID,
  MAINTENANCE_MODE,
  runningInProduction,
} from 'utils/constants';
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { identifyUser } from "utils/analytics/analytics";
import { setCookiesFromQueryParams } from "utils/setCookiesFromQueryParams";
import ErrorBoundary from "components/ErrorBoundary";
import { useRouterEvents } from "hooks/ui/useRouterEvents";
import ScriptLayer from "scripts/ScriptLayer";
import { saveUtmParams } from 'utils/saveUtmParams';
import { GoogleOAuthProvider } from '@react-oauth/google';

Sentry.init({
  environment: runningInProduction ? 'production' : 'development',
  // A `null` DNS is the official way of disabling the SDK.
  dsn: runningInProduction
    ? 'https://ef2866d0a06245d99d69e16c683dc94c@o386295.ingest.sentry.io/5220387'
    : 'https://5da9b79462deafe41d5900e5dbb9c237@o4506536506753024.ingest.sentry.io/4506536508588032',
  ignoreErrors: [
    'adsbygoogle.push() error: No slot size for availableWidth=0', // Ads work fine, so this (very common) error is not helpful
  ],
});

const reportSentryError = (error: any, errorInfo: any) => {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });

    Sentry.captureException(error);
  });
};

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  useRouterEvents(router, setLoading);

  useEffect(() => {
    const emailAddress = localStorage['login_email_address'] || '';

    if (emailAddress !== '') {
      Sentry.setUser({
        email: emailAddress,
      });
    }

    setCookiesFromQueryParams(router.query);
    saveUtmParams(router.query);
  }, [router.query, router.isReady]);

  if (MAINTENANCE_MODE) {
    return <Maintenance />;
  }
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <RootStoreProvider hydrationData={pageProps.hydrationData}>
        <ErrorBoundary reportSentryError={reportSentryError}>
          {loading && <LoadingOverlay />}
          <DefaultSeo {...SEO} />
          <ScriptLayer />
          {router.isReady && <Component {...pageProps} />}
        </ErrorBoundary>
      </RootStoreProvider>
    </GoogleOAuthProvider>
  );
}
