import { IAdditionalPromptInfo, Prize } from 'types/Prize';
import { generateOptionalQueryString, performFetchRequest } from './common';

interface IPrize {}

export const validatePrizeWinIdForUse = async (
  jwt: string,
  prizeWinId: string
) => {
  return await performFetchRequest(
    `/prizes/validate-pwid-for-use?pwid=${prizeWinId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchPrizesForUser = async (jwt: string) => {
  return await performFetchRequest('/prizes/fetch-prizes-for-user', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const submitPrizeChoice = async (
  jwt: string,
  prizeWinId: string,
  chosenPrizeInstanceId: number,
  additionalPromptInfo: Record<string, IAdditionalPromptInfo> | null,
  selectedOption: {
    title: string;
    imageLocation: string;
  } | null
) => {
  return await performFetchRequest('/prizes/submit-prize-choice', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      prizeWinId,
      chosenPrizeInstanceId,
      additionalPromptInfo,
      selectedOption,
    }),
  });
};

export const fetchPrizeBySlug = async (
  jwt: string,
  slug: string,
  incrementViewCount: boolean
): Promise<{ successful: boolean; reason?: string; prize?: Prize }> => {
  return await performFetchRequest(
    `/prizes/fetch-prize-by-slug?slug=${slug}&incrementViewCount=${incrementViewCount}`, {
      headers: {
        'x-access-token': jwt,
      }
    }
  );
};

// includes hidden prizes to show in prize preview page
export const fetchPrizePreviewBySlug = async (
  slug: string,
  incrementViewCount: boolean,
): Promise<{ successful: boolean; reason?: string; prize?: Prize }> => {
  return await performFetchRequest(
    `/prizes/fetch-prize-by-slug-preview?slug=${slug}&incrementViewCount=${incrementViewCount}`
  );
};

export const fetchLegacyPrizesForValue = async (valueInPounds: string) => {
  return await performFetchRequest(
    `/prizes/fetch-legacy-prizes?value=${valueInPounds}`
  );
};

export const fetchSearchResults = async (searchTerm: string) => {
  return await performFetchRequest(
    `/prizes/fetch-search-results?searchTerm=${searchTerm}`
  );
};

export const fetchPrizes = async (
  optionalJwt?: string,
  exchangingTokensForPrize?: string,
  optionalValueFilter?: number | null
) => {
  const url = optionalJwt
    ? '/prizes/fetch-prizes-with-favourited-flag'
    : '/prizes/fetch-prizes';
  const action = exchangingTokensForPrize
    ? 'exchanging-tokens-for-prize'
    : null;
  return await performFetchRequest(url, {
    method: 'POST',
    headers: {
      'x-access-token': optionalJwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      action,
      optionalValueFilter,
    }),
  });
};

export const fetchFavouritedPrizes = async (jwt: string) => {
  return await performFetchRequest('/prizes/fetch-favourited-prizes', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const fetchPrizesForLandingPage = async (lpid: string) => {
  return await performFetchRequest(
    `/prizes/fetch-prizes-for-landing-page?lpid=${lpid}`
  );
};

export const fetchTrendingPrizes = async () => {
  return await performFetchRequest('/prizes/fetch-trending-prizes');
};

export const fetchCategorisedFeaturedPrizes = async () => {
  return await performFetchRequest('/prizes/fetch-categorised-featured-prizes');
};

export const fetchPrizesForValidationResult = async (
  jwt: string,
  validationResult: { valid: boolean; prizeWinId: string }
) => {
  return await performFetchRequest(
    '/prizes/fetch-prizes-for-validation-result',
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        validationResult,
      }),
    }
  );
};

export const fetchSimilarPrizes = async (prizeId: string) => {
  return await performFetchRequest(
    `/prizes/fetch-similar-prizes?prizeId=${prizeId}`
  );
};

export const submitRatingForPrize = async (
  jwt: string,
  pwid: string,
  rating: string
) => {
  return await performFetchRequest('/prizes/submit-rating-for-prize', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pwid,
      rating,
    }),
  });
};

export const submitRatingCommentsForPrize = async (
  jwt: string,
  pwid: string,
  comments: string
) => {
  return await performFetchRequest('/prizes/submit-rating-comments-for-prize', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pwid,
      comments,
    }),
  });
};

export const fetchPrizeSlugs = async () => {
  return await performFetchRequest('/prizes/prize-slugs');
};

export const toggleFavouritedPrize = async (
  jwt: string,
  prizeInstanceId: number,
  favourited: boolean
) => {
  return await performFetchRequest(
    '/prizes/toggle-favourited-prize?prizeInstanceId',
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prizeInstanceId,
        favourited,
      }),
    }
  );
};

export const prizeWasWonByGuest = async (
  pwid: string,
  emailAddress: string
) => {
  return await performFetchRequest(
    `/prizes/prize-was-won-by-guest?pwid=${pwid}&emailAddress=${encodeURIComponent(
      emailAddress
    )}`
  );
};

export const fetchTreatWinsForUser = async (jwt: string, drawId: string) => {
  return await performFetchRequest(
    `/prizes/treat-prize-wins-for-user${generateOptionalQueryString({
      drawId,
    })}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchPrizeInstancesByPrizeId = async (prizeId: string) => {
  return await performFetchRequest(`/prizes/instances?prizeId=${prizeId}`);
};

// To be called by operators only
export const fetchAllActiveSubcategories = async (jwt: string) => {
  return await performFetchRequest('/prizes/fetch-all-active-subcategories', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

// Determines what categories to display in header/subcategory selector.
export const fetchGroupedActiveSubcategories = async () => {
  return await performFetchRequest(
    '/prizes/fetch-grouped-active-subcategories'
  );
};
