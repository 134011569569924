import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { Toast } from 'types/ui/Toast';
import { HeaderBanner } from 'types/ui/HeaderBanner';

export type UIHydration = {
  toast?: Toast;
  headerBanner?: HeaderBanner;
  drawCounterInHeader?: boolean
};

export class UIStore {
  root: RootStore;
  toast: Toast | null = null;
  headerBanner: HeaderBanner | null = null;
  drawCounterInHeader: boolean = false
  private toastTimeout?: ReturnType<typeof setTimeout>;

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this, {
      displayToast: action,
      displayHeaderBanner: action,
      toast: observable,
      headerBanner: observable,
      drawCounterInHeader: observable,
      showDrawCounterInHeader: action
    });
  }

  displayToast = (toast: Toast | null) => {
    this.toast = toast;
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout);
    }
    if (toast) {
      this.toastTimeout = setTimeout(() => {
        this.toast = null;
        this.toastTimeout = undefined;
      }, toast.timeout);

      return this.toastTimeout;
    }
  };

  displayHeaderBanner = (banner: HeaderBanner | null) => {
    this.headerBanner = banner;
  };

  showDrawCounterInHeader = (show: boolean) => {
    this.drawCounterInHeader = show
  }

  hydrate(data?: UIHydration) {
    if (data?.toast) {
      this.toast = data.toast;
    }
    if (data?.headerBanner) {
      this.headerBanner = data.headerBanner;
    }
    if(data?.drawCounterInHeader) {
      this.drawCounterInHeader = data.drawCounterInHeader
    }
  }
}
