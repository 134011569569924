import { utmParamsFromQuery } from './common';

export const saveUtmParams = (params: any) => {
  const utmParams = utmParamsFromQuery(params);
  if (Object.keys(utmParams).length > 0) {
    localStorage.setItem(
      'utm_params',
      JSON.stringify({
        insert_date: new Date(),
        ...utmParams,
      })
    );
  }
};
